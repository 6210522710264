var Main = (function() {

    function windowScrollListener()
    {
        $(window).on('scroll', function() {

            var scrollPosition = $(window).scrollTop();
        });
    }

    function windowResizeListener()
    {
        $(window).on('resize', function() {
            var $nav = $('nav#main-navigation');
            var $menuBtn = $('button.tcon');
            var $sidebar = $('div#sidebar');

            if (!is_sm_device()) { //close menu and remove active classes
                // Hamburger and navigation logic
                if ($menuBtn.hasClass('tcon-transform')) {
                    $menuBtn.removeClass('tcon-transform');
                    $nav.find('li.active').removeClass('active').find('ul.submenu').slideUp(300);
                }

                // Sidebar filter logic

                if ($sidebar.find('.filter-wrapper').is(':hidden')) {
                    $sidebar.find('.filter-wrapper').show();
                    $sidebar.find('span.filter-toggler').addClass('active').find('span.inactive').hide().end().find('span.active').show();
                }
            } else {
                // Hamburger and navigation logic
                if ($nav.is(':visible') && $menuBtn.hasClass('tcon-transform') == false) {
                    $menuBtn.addClass('tcon-transform');
                }
            }
        });
    }

    function keypressListener()
    {
        $(document).keyup(function(e) {

            if(e.keyCode === 27) { // esc

            }
        });
    }

    function _scrollToTarget(target, callback)
    {
        $('html, body').stop(true,true).animate({scrollTop: $(target).offset().top }, 700, (callback == undefined) ? function() {} : callback);
    }

    function initFlatUI()
    {
        $("select").select2({dropdownCssClass: 'dropdown-inverse'});

        $(':checkbox').radiocheck();

        var $priceFrom = $('input#price_from');
        var $priceTo = $('input#price_to');

        $("#products-price-rangeslider").ionRangeSlider({
            type: "double",
            step: 5,
            min: 1000,
            max: 2000,
            from: 1200,
            to: 1800,
            hide_min_max: true,
            hide_from_to: false,
            grid: false,
            postfix: "&euro;",
            onFinish: function (data) {
                var priceFrom = data.from;
                var priceTo = data.to;

                $priceFrom.val(priceFrom);
                $priceTo.val(priceTo);
            }
        });

        $(':radio').radiocheck();
    }

    function initContactPage()
    {
        if( $('section#contact').length ) {
            _initGoogleMaps();
            _initFormValidation();
        }
    }

    function _initGoogleMaps()
    {
        var place = {lat: parseFloat(companyInfo.latitude), lng: parseFloat(companyInfo.longitude)};
        var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 14,
            center: place
        });
        var marker = new google.maps.Marker({
            position: place,
            map: map,
            icon: baseUrl + 'wp/map-marker.png'
        });
    }

    function _initFormValidation()
    {
        $.validator.methods.phone = function( value, element ) {
            return this.optional( element ) || /^(\+)?[0-9]+[0-9 ]*$/.test( value );
        }

        $('#contact-form').validate({
            rules: {
                name:
                {
                    required: true
                },
                email:
                {
                    required: true,
                    email: true
                },
                phone:
                {
                    required: true,
                    phone: true
                },
                message:
                {
                    required: true,
                }
            },
            messages:
            {
                name:
                {
                    required: customValidationRules.name_required,
                },
                email:
                {
                    required: customValidationRules.email_required,
                    email: customValidationRules.email_email
                },
                phone:
                {
                    required: customValidationRules.phone_required,
                    phone: customValidationRules.phone_phone
                },
                message:
                {
                    required: customValidationRules.message_required
                }
            },
            submitHandler: function(form)
            {
                $('button#contact-form-submit').prop('disabled', true);
                form.submit();
            },
            errorElement: 'span',
            errorPlacement: function(error, element)
            {
                error.addClass('input_error').appendTo(element.parent());
            }
        });
    }

    function initOrderForm()
    {
        $('div.order-details').find(':checkbox').on('change.radiocheck', function() {
            if ($(this).hasClass('section-trigger')) {
                var $target = $('#' + $(this).data('target'));

                $target.stop(true, true).slideToggle(400);
            }
        });
    }

    function initQuantitySpinners()
    {
        var $spinners = $('body').on('click', 'form.add-to-cart button.spinner', function(e) {
            var type = $(this).hasClass('cart-minus') ? 'decrement' : 'increment';
            var $input = $(this).parent().find('input[name="quantity"]');
            var currentVal = parseInt($input.val());

            if (type == 'increment') {
                currentVal = currentVal ? currentVal : 0;
                $input.val(currentVal+1);
            } else {
                if (currentVal > 0 && currentVal !== 1) {
                    $input.val(currentVal-1);
                }
            }

            if ($(this).hasClass('updater')) {
                _updateCartItem($(this).closest('form'));
            }

            e.preventDefault();
        });

        // Enable only numbers in update cart input
        $('body').on('keydown', 'form.add-to-cart input', function(e) {

            if ($.inArray(e.keyCode, [46, 8, 9, 27]) !== -1 ||
                (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                (e.keyCode >= 35 && e.keyCode <= 40)) {
                return;
            }

            if(e.keyCode == 13) {
                $(this).blur();
                return;
            }

            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        });
    }

    function addToCartBtnListener()
    {
        $('body').on('click', 'button.add-to-cart', function(e) {
            var $form = $(this).closest('form');
            var $submit = $(this);
            var url = $form.prop('action');
            var quantity = $form.find('input[name="quantity"]').val();
            var product = $form.data('name');

            $.ajax({
                type: 'POST',
                url: url,
                data: $form.serialize(),
                dataType: 'json',
                beforeSend: function () {
                    $submit.prop('disabled', true);
                }
            }).promise().done(function (response) {
                $submit.prop('disabled', false);
                if(response.type == 'success') {
                    $('span#top-cart-items').text(response.items);
                    $('span#top-cart-price-vat').text(response.priceVat);
                    $('span.added-product-quantity').text(quantity);
                    $('span.added-product').text(product);
                    $('#add-to-cart-success-modal').modal('show');
                }
            }).fail(function (response) {
                $submit.prop('disabled', false);
            });

            e.preventDefault();
        });
    }

    function cartUpdater()
    {
        var $form = $('form.add-to-cart.cart');

        $form.find('input').on('focusout', function() {
           _updateCartItem($(this).closest('form'));
        });
    }

    function _updateCartItem(formObject)
    {
        var $form = formObject;

        var url = $form.prop('action');
        var $quantity = $form.find('input[name="quantity"]');

        if($quantity.val() == 'NaN') {
            $quantity.val(1);
        }

        var quantity = $quantity.val();

        $.ajax({
            type: 'POST',
            url: url,
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function () {
            }
        }).promise().done(function (response) {
            if(response.type == 'success') {
                $('span#top-cart-items').text(response.items);
                $('span#top-cart-price-vat').text(response.priceVat);
                $('span#cart-total-price').text(response.price);
                $('span#cart-total-vat').text(response.vat);
                $('span#cart-total-price-vat').text(response.priceVat);
                $form.closest('tr.trow').find('span.total').text(response.rowTotal);
            }
        }).fail(function (response) {
        });
    }

    function initMobileNavigation()
    {
        var $nav = $('nav#main-navigation');

        $nav.on('click', 'li.top-level > a', function(e) {
            if (is_sm_device()) {
                if ($(this).parent().hasClass('active')) {
                    $(this).parent().removeClass('active').find('ul.submenu').stop(true, true).slideUp(300);
                } else {
                    $(this).parent().siblings().find('ul.submenu').stop(true, true).slideUp(300, function() {
                        $(this).parent().removeClass('active');
                    });
                    $(this).parent().addClass('active').find('ul').stop(true, true).slideDown(300, function() {
                        _scrollToTarget($(this).parent());
                    });
                }

                e.preventDefault();
            }
        });

        $('body').on('click', 'button.tcon', function() {
           $nav.stop(true, true).slideToggle(300);
        });
    }

    function sidebarFilterToggler()
    {
        $('div#sidebar').on('click', 'span.filter-toggler', function() {
            console.log('click');
            var $btn = $(this);
            if ($btn.hasClass('active')) {
                $btn.removeClass('active').next().stop(true, true).slideUp(300);
                $btn.find('.active').hide().end().find('.inactive').show();
            } else {
                $btn.addClass('active').next().stop(true, true).slideDown(300);
                $btn.find('.inactive').hide().end().find('.active').show();
            }
        });
    }

    function deliveryCheckboxListener()
    {
        console.log('delivery listener');
        $('div.delivery-payment').find(':radio').on('change.radiocheck', function() {
            var $spanInfo = $('span#delivery-price-url');
            var url = $spanInfo.data('url');
            var token = $spanInfo.data('token');
            var currentPayment = $('input[name="payment"]:checked').val();
            var currentDelivery = $('input[name="delivery"]:checked').val();
            var cartPriceVat = $('#total-price-vat').data('original-value');

            $.ajax({
                type: 'POST',
                url: url,
                data: {
                    payment: currentPayment,
                    delivery: currentDelivery,
                    price_vat: cartPriceVat,
                    _token: token
                },
                dataType: 'json',
                beforeSend: function () {
                }
            }).promise().done(function (response) {

                $('span#delivery-price-vat').text(response.delivery_price_vat);
                $('span#price').text(response.price);
                $('span#vat').text(response.vat);
                $('span#total-price-vat').text(response.total_price_vat);

                for (var key in response.deliveryMethods) {
                    if (!response.deliveryMethods.hasOwnProperty(key)) continue;

                    var deliveryMethod = response.deliveryMethods[key];
                    $('input#' + key).parent().find('span.price').text(deliveryMethod.price_vat);
                }

            }).fail(function (response) {
            });
        });
    }

    return {
        init: function()
        {
            windowScrollListener();
            windowResizeListener();
            keypressListener();
            //initFlatUI();
            initOrderForm();
            initQuantitySpinners();
            addToCartBtnListener();
            cartUpdater();
            //initMobileNavigation();
            sidebarFilterToggler();
            deliveryCheckboxListener();
        },
        initContactPage: initContactPage
    };
})();
